import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import {  UIDialogTitle, UIFileMultipleInput } from '@shared/components';
import React from 'react';
import { globalEnums } from '@shared/duck';
import { Form, Formik, FormikHelpers } from 'formik';
import { harTypes, harUtils } from './duck';
import { AttachedFile } from './components';
import { useParams } from 'react-router-dom';

interface HarUploadModalProps {
  onClose: () => void;
}

const harSupportedTypes = [
  globalEnums.EFileExtension.HAR,
  globalEnums.EFileExtension.JSON,
];

export const HarUploadModal: React.FC<HarUploadModalProps> = ({
  onClose,
}) => {
  const { targetId = '' } = useParams();
  const [openModal, setOpenModal] = React.useState(true);

  const handleClose = () => {
    setOpenModal(false);
  };

  const { onSubmit } = harUtils.useSubmit(targetId);
  const onSubmitForm = async (values: harTypes.HarUploadModalValues, formikHelpers: FormikHelpers<harTypes.HarUploadModalValues>) => {
    await onSubmit(values, formikHelpers);

    // if (success) {
    //   onClose();
    // }
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      onTransitionExited={onClose}
      fullWidth
      maxWidth='sm'
      scroll='paper'
    >
      <UIDialogTitle
        title={'Add HAR files'}
        onCloseBtnAction={handleClose}
      />
      <Formik<harTypes.HarUploadModalValues>
        initialValues={{ values: [] }}
        onSubmit={onSubmitForm}
        validationSchema={harUtils.harFilesValidationSchema}
        validateOnMount
        validateOnChange
        validateOnBlur
      >
        {( { values, setFieldValue, isValid, isSubmitting, handleSubmit, validateForm  }) => {

          React.useEffect(() => {
            const validate = async () => {
              if (!isSubmitting) {
                validateForm({ ...values });
              }
            };

            validate();
          }, [JSON.stringify(values), isSubmitting]);

          return (
            <>
              <Form style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                <DialogContent
                  dividers
                  sx={{ padding: '2rem 1.5rem' }}
                >
                  <Stack gap='1rem'>
                    <UIFileMultipleInput
                      onUploadFiles={(files: File[]) => {
                        const newValues: harTypes.HarObject[] = files.map(file => ({
                          file: file,
                          fileName: file.name,
                        }));
                        setFieldValue('values', [...values.values, ...newValues]);
                      }}
                      accept={harSupportedTypes}
                      helpText='.HAR or .JSON files'
                    />
                    <Stack>
                      <Typography variant='subtitle1' fontWeight='500'>
                        Files:
                      </Typography>
                      {values.values.length ? (
                        values.values.map((obj, index) => (
                          <AttachedFile index={index} key={`${obj.file.name}-${index}`} targetId={targetId || ''}/>
                        ))
                      ) : (
                        <Typography variant='default'>
                          <em>No files added.</em>
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </DialogContent>
              </Form>

              <DialogActions>
                <Stack
                  width='100%'
                  flexDirection='row'
                >
                  <Button
                    variant='outlined'
                    color='error'
                    onClick={() => handleClose()}
                    disabled={isSubmitting}
                  >
                    {'Cancel'}
                  </Button>

                  <Button
                    variant='contained'
                    sx={{ marginLeft: 'auto' }}
                    onClick={() => handleSubmit()}
                    disabled={!isValid || isSubmitting}
                    endIcon={isSubmitting && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                  >
                    {'Upload'}
                  </Button>
                </Stack>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default HarUploadModal;