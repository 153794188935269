import { useFlags } from 'launchdarkly-react-client-sdk';

const useLaunchDarklyFlags = () => {
  const flags = useFlags();

  /** Note: for dev use 'true' inside 'Boolean' */

  return {
    showEngineIcon: Boolean(flags?.['show-engine-icon']),
    vulnerableShowcase: Boolean(flags?.['vulnerable-showcase']),
    freshchat: Boolean(flags?.['freshchat']),
    strictSwaggerSpecValidation: Boolean(flags?.['strict-swagger-spec-validation']),
    scanPlanner: Boolean(flags?.['scan-planner']),
    appConfigToTarget: Boolean(flags?.['application-config-to-target']),
    newPathsTableWithCodes: Boolean(flags?.['new-scan-paths-table-with-response-codes']),
    harFiles: Boolean(flags?.['har-files']),
  };
};

export default useLaunchDarklyFlags;
