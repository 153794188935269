import { useApp } from '@shared/hooks';
import {
  InfiniteQueryObserverOptions,
  QueryObserverOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import * as apiClient from 'api-client';
import { AxiosError, AxiosResponse } from 'axios';
import { first } from 'lodash';
import { useEffect, useState } from 'react';
import { PathInfo } from '../types';
import { ScansQueryKeys } from './queriesKeys';
import { formatTimestamp, getNextLogsPage, useFlatLogs } from './scansQueriesUtils';
import { getFlatResults, getNextPageParam, useFlatCountFromResponse, useFlatResults } from './utils';

const useGetScansList = (
  request: apiClient.ScansApiScansListRequest,
  config?: Partial<InfiniteQueryObserverOptions<AxiosResponse<apiClient.PaginatedScanList>, AxiosError>>,
) => {
  const { scansApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [ScansQueryKeys.scansList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => scansApi.scansList({ ...request, page: pageParam }),

    getNextPageParam: getNextPageParam,
    ...config,
  });

  const scans = getFlatResults(data);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    scans,
    totalItemsCount,
    isScansListLoading: isLoading,
    scansHasNextPage: hasNextPage,
    fetchNextScansPage: fetchNextPage,
  };
};

const useGetLastScanMutation = () => {
  const { scansApi } = useApp();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.ScansApiScansListRequest) => scansApi.scansList({
      ...request,
      limit: 1,
      order: ['-created_at'],
    }),
  });

  return {
    getLastScan: mutateAsync,
  };
};

const useGetScan = (
  request: apiClient.ScansApiScansRetrieveRequest,
  config?: Partial<QueryObserverOptions<AxiosResponse<apiClient.Scan>, AxiosError>>,
) => {
  const { scansApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [ScansQueryKeys.scanById, ...Object.values(request)],
    queryFn: () => scansApi.scansRetrieve(request),
    enabled: !!request.id,
    ...config,
  });

  return {
    scan: data?.data,
    isScanLoading: request.id ? isLoading : false,
  };
};

const useUpdateScan = () => {
  const { scansApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.ScansApiScansUpdateRequest) => scansApi.scansUpdate(request),
    onSuccess: () => [
      queryClient.invalidateQueries({ queryKey: [ScansQueryKeys.scansList] }),
      queryClient.resetQueries({ queryKey: [ScansQueryKeys.scanById] }),
    ]
  });

  return {
    updateScan: mutateAsync,
  };
};

const useGetOwaspLogs = (
  request: apiClient.ScansApiScansChecksGetOwaspLogsRetrieveRequest,
  config?: Partial<QueryObserverOptions<AxiosResponse<apiClient.LogsUrl>, AxiosError>>,
) => {
  const { scansApi } = useApp();
  const { data, isLoading, fetchStatus } = useQuery({
    queryKey: [ScansQueryKeys.owaspLogsRetrieve, ...Object.values(request)],
    queryFn: () => scansApi.scansChecksGetOwaspLogsRetrieve(request),
    ...config,
    enabled: !!request.id && (
      config ? config.enabled : true
    ),
  });

  return {
    logsUrl: data?.data,
    islogsUrlLoading: isLoading && fetchStatus !== 'idle',
  };
};

const useCreateScans = () => {
  const { scansApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.ScansApiScansCreateRequest) => scansApi.scansCreate(request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [ScansQueryKeys.scansList] }),
  });

  return {
    createScans: mutateAsync,
  };
};

const useDeleteScan = () => {
  const { scansApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.ScansApiScansDestroyRequest) => scansApi.scansDestroy(request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [ScansQueryKeys.scansList] }),
  });

  return {
    deleteScan: mutateAsync,
    isDeleteInProgress: isLoading,
  };
};

const useDeleteScans = () => {
  const { scansApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.ScansApiScansDropDestroyRequest) => scansApi.scansDropDestroy(request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [ScansQueryKeys.scansList] }),
  });

  return {
    deleteScans: mutateAsync,
    isDeleteInProgress: isLoading,
  };
};

const useAbortScan = () => {
  const { scansApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.ScansApiScansKillCreateRequest) => scansApi.scansKillCreate(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [ScansQueryKeys.scansList] });
      queryClient.invalidateQueries({ queryKey: [ScansQueryKeys.scanById] });
    },
  });

  return {
    abortScan: mutateAsync,
    isAbortInProgress: isLoading,
  };
};

const getLastFetchedCheckedPath = (lastPage?: AxiosResponse<apiClient.CheckedPaths>) => lastPage?.data?.has_more &&
  lastPage?.data?.results.slice(-1)[0];

const PAGE_SIZE = 25;

const useGetScanCheckedPathsList = (
  request: apiClient.ScansApiScansCheckedPathsRetrieveRequest,
  config?: Partial<InfiniteQueryObserverOptions<AxiosResponse<apiClient.CheckedPaths>, AxiosError>>,
) => {
  const { scansApi } = useApp();
  const { filter, ...requestWithoutFilter } = request;
  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [ScansQueryKeys.scansPathsList, ...Object.values(requestWithoutFilter)],
    queryFn: ({ pageParam: lastFetchedPath = {} }) => scansApi.scansCheckedPathsRetrieve({
      ...requestWithoutFilter,
      after: lastFetchedPath?.path,
      afterHttpMethod: lastFetchedPath?.http_method,
    }),
    getNextPageParam: getLastFetchedCheckedPath,
    ...config,
    enabled: !!request.id && (
      config ? config.enabled : true
    ),
  });

  // fetch all pages of paths
  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, data?.pages.length]);

  const flatResults = useFlatResults(data).filter(p => p.path.includes(filter || ''));

  const [paths, setPaths] = useState<PathInfo[]>([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    setPaths(flatResults.map((p, i) => (
      {
        id: i.toString(),
        path: p.path,
        http_codes: p.response_codes,
        http_method: p.http_method,
      }
    )).slice(0, page * PAGE_SIZE));
  }, [page, flatResults]);

  return {
    paths,
    isPathsLoading: isLoading,
    pathsHasNextPage: (
      paths.length < flatResults.length
    ) || hasNextPage,
    fetchNextPathsPage: () => {
      setPage(page + 1);
    },
    pathsTotal: first(data?.pages)?.data?.count,
  };
};

const useGetScanPlaywrightLoginStatus = (
  request: apiClient.ScansApiScansLoginStatusRetrieveRequest,
  config?: Partial<QueryObserverOptions<AxiosResponse<apiClient.PlaywrightLoginStatus>, AxiosError>>,
) => {
  const { scansApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [ScansQueryKeys.scanPlaywrightLoginStatus, ...Object.values(request)],
    queryFn: () => scansApi.scansLoginStatusRetrieve(request),
    ...config,
    enabled: !!request.id && (
      config ? config.enabled : true
    ),
  });

  return {
    playwrightLoginStatus: data?.data,
    isPlaywrightLoginStatusLoading: isLoading,
  };
};

const useGetScanLogsList = (
  request: apiClient.ScansApiScansLogsRetrieveRequest,
  config?: Partial<InfiniteQueryObserverOptions<AxiosResponse<apiClient.LogsResponse>, AxiosError>>,
) => {
  const { scansApi } = useApp();
  const { data, isFetching, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: [ScansQueryKeys.scanLogs, ...Object.values(request)],
    queryFn: ({ pageParam = undefined }) => scansApi.scansLogsRetrieve({ ...request, next: pageParam }),
    getNextPageParam: getNextLogsPage,
    ...config,
    enabled: !!request.id && (
      config ? config.enabled : true
    ),
  });

  // fetch all pages of logs
  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, data?.pages.length]);

  const messages = useFlatLogs(data);

  const isLoading = (
    hasNextPage ?? false
  ) || isFetching;

  return {
    // use log position as id to avoid incorrect behavior when timestamps are the same
    logs: messages.map((m, index) => (
      { ...m, id: index, timestampFormatted: formatTimestamp(m.timestamp) }
    )),
    isLogsLoading: isLoading,
    fetchNextLogsPage: fetchNextPage,
    refetchLogs: refetch,
  };
};

const useGetScanChecksList = (
  request: apiClient.ScansApiScansChecksListRequest,
  config?: Partial<InfiniteQueryObserverOptions<AxiosResponse<apiClient.PaginatedCheckList>, AxiosError>>,
) => {
  const { scansApi } = useApp();
  const { data, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [ScansQueryKeys.scanChecks, ...Object.values(request)],
    queryFn: ({ pageParam = undefined }) => scansApi.scansChecksList({ ...request, page: pageParam }),
    getNextPageParam: getNextPageParam,
    ...config,
    enabled: !!request.id && (
      config ? config.enabled : true
    ),
  });

  const checks = useFlatResults(data);

  return {
    checks: checks,
    isChecksLoading: isFetching,
    fetchNextChecksPage: fetchNextPage,
    checksHasNextPage: hasNextPage,
  };
};

const useGetScanChecksListMutation = () => {
  const { scansApi } = useApp();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.ScansApiScansChecksListRequest) => scansApi.scansChecksList(request),
  });

  return {
    getChecksList: mutateAsync,
    isChecksListLoading: isLoading,
  };
};

const useGetOpenApiSpecUrlFromScanMutation = () => {
  const { scansApi } = useApp();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.ScansApiScansGetSpecUrlRetrieveRequest) => scansApi.scansGetSpecUrlRetrieve(request),
  });

  return {
    getOpenApiSpecUrl: mutateAsync,
  };
};


export {
  useGetScansList,
  useGetLastScanMutation,
  useCreateScans,
  useDeleteScan,
  useDeleteScans,
  useGetOwaspLogs,
  useGetScan,
  useGetScanCheckedPathsList,
  useGetScanPlaywrightLoginStatus,
  useGetScanLogsList,
  useGetScanChecksList,
  useUpdateScan,
  useGetScanChecksListMutation,
  useGetOpenApiSpecUrlFromScanMutation,
  useAbortScan,
};
